import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { useEffect } from "react";
import { logout } from "utils/logout";
import { connect, useDispatch } from "react-redux";
import AuthApiService from "../../services/AuthService";

const TIME_INTERVAL = 50 * 60 * 1000;

export const AppViews = ({ currentUser }) => {
  const dispatch = useDispatch();

  const refreshToken = () => {
    AuthApiService.getToken()
    .then((response) => {
      localStorage.setItem('cloud_token', response.data.body.access_token);
    });
  };


  useEffect(() => {
    if (!currentUser) logout(dispatch);

    setInterval(refreshToken, TIME_INTERVAL);
  }, []);

  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />
        <Route path={`${APP_PREFIX_PATH}/partners`} component={lazy(() => import(`./partners`))} />
        <Route path={`${APP_PREFIX_PATH}/partners/:guid/detail`} component={lazy(() => import(`./partners`))} />
        <Route path={`${APP_PREFIX_PATH}/customers`} component={lazy(() => import(`./customers`))} />
        <Route path={`${APP_PREFIX_PATH}/customers/:guid`} component={lazy(() => import(`./customers`))} />
        <Route path={`${APP_PREFIX_PATH}/subscriptions`} component={lazy(() => import(`./subscriptions`))} />
        <Route path={`${APP_PREFIX_PATH}/subscriptions/:guid`} component={lazy(() => import(`./subscriptions`))} />
        <Route path={`${APP_PREFIX_PATH}/documents`} component={lazy(() => import(`./documents`))} />
        <Route path={`${APP_PREFIX_PATH}/documents/filtered/:guid`} component={lazy(() => import(`./documents`))} />
        <Route path={`${APP_PREFIX_PATH}/documents/:guid`} component={lazy(() => import(`./documents`))} />
        <Route path={`${APP_PREFIX_PATH}/users`} component={lazy(() => import(`./users`))} />
        <Route path={`${APP_PREFIX_PATH}/users/new`} component={lazy(() => import(`./users`))} />
        <Route path={`${APP_PREFIX_PATH}/users/:id`} component={lazy(() => import(`./users`))} />
        <Route path={`${APP_PREFIX_PATH}/users/edit/:guid`} component={lazy(() => import(`./users`))} />
        <Route path={`${APP_PREFIX_PATH}/bill`} component={lazy(() => import(`./bill`))} />
        <Route path={`${APP_PREFIX_PATH}/activity`} component={lazy(() => import(`./activity`))} />
        <Route path={`${APP_PREFIX_PATH}/settings`} component={lazy(() => import(`./settings`))} />
        <Route path={`${APP_PREFIX_PATH}/settings/avatars`} component={lazy(() => import(`./settings`))} />
        <Route path={`${APP_PREFIX_PATH}/advanced-settings`} component={lazy(() => import(`./advanced-settings`))} />
        {/* <Route path={`${APP_PREFIX_PATH}/jwt`} component={lazy(() => import(`./jwt`))} /> */}

        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  )
}

const mapStateToProps = (state) => (
	{
		currentUser: state.api.currentUser,
	}
)

export default connect(mapStateToProps,null)(React.memo(AppViews));